var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    {
      ref: "addMovieForm",
      staticClass: "w-screen mb-10",
      attrs: { tag: "div" },
    },
    [
      _c("div", { staticClass: "container mx-auto" }, [
        _c("h1", [
          _vm._v(_vm._s(this.movie && this.movie.uuid ? "編集" : "登録")),
        ]),
        _c("div", { staticClass: "w-full mt-4 movie_menu" }, [
          _c(
            "div",
            { staticClass: "pb-2 my-2 flex items-center justify-between" },
            [
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "outline" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("outline")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("①" + _vm._s(_vm.$t("commons.movie_tabs.required"))),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "detail" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("detail")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("②" + _vm._s(_vm.$t("commons.movie_tabs.detail"))),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "format" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("format")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("③" + _vm._s(_vm.$t("commons.movie_tabs.format"))),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "cast" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("cast")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("④" + _vm._s(_vm.$t("commons.movie_tabs.casts"))),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "gallery" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("gallery")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("⑤" + _vm._s(_vm.$t("commons.movie_tabs.gallery"))),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "pt-3 font-semibold hover:text-black",
                  class: [
                    _vm.activeMenu == "award" ? "active" : "text-brown-grey",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.changeActive("award")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("⑥" + _vm._s(_vm.$t("commons.movie_tabs.award"))),
                  ]),
                ]
              ),
              _vm.loginUserRole == "manager"
                ? _c(
                    "button",
                    {
                      staticClass: "pt-3 font-semibold hover:text-black",
                      class: [
                        _vm.activeMenu == "data" ? "active" : "text-brown-grey",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.changeActive("data")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("commons.movie_tabs.data"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "border border-light-grey" }),
      _c(
        "div",
        { staticClass: "container mx-auto" },
        [
          _vm.activeMenu == "outline"
            ? _c("outline", {
                attrs: {
                  errors: _vm.otherFieldErrors,
                  temporarySave: _vm.isTemporarySave,
                },
                model: {
                  value: _vm.movie,
                  callback: function ($$v) {
                    _vm.movie = $$v
                  },
                  expression: "movie",
                },
              })
            : _vm._e(),
          _vm.activeMenu == "detail"
            ? _c("detail", {
                model: {
                  value: _vm.movie,
                  callback: function ($$v) {
                    _vm.movie = $$v
                  },
                  expression: "movie",
                },
              })
            : _vm._e(),
          _vm.activeMenu == "format"
            ? _c("format", {
                model: {
                  value: _vm.movie,
                  callback: function ($$v) {
                    _vm.movie = $$v
                  },
                  expression: "movie",
                },
              })
            : _vm._e(),
          _vm.activeMenu == "cast"
            ? _c("cast", {
                model: {
                  value: _vm.movie,
                  callback: function ($$v) {
                    _vm.movie = $$v
                  },
                  expression: "movie",
                },
              })
            : _vm._e(),
          _c("gallery", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeMenu == "gallery",
                expression: "activeMenu == 'gallery'",
              },
            ],
            model: {
              value: _vm.movie,
              callback: function ($$v) {
                _vm.movie = $$v
              },
              expression: "movie",
            },
          }),
          _vm.activeMenu == "award"
            ? _c("award", {
                model: {
                  value: _vm.movie,
                  callback: function ($$v) {
                    _vm.movie = $$v
                  },
                  expression: "movie",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.activeMenu == "data"
        ? _c("admin-data", {
            model: {
              value: _vm.movie,
              callback: function ($$v) {
                _vm.movie = $$v
              },
              expression: "movie",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "border border-brown-grey" }),
      _c("div", { staticClass: "container mx-auto py-4 text-center" }, [
        _c(
          "div",
          { staticClass: "w-2/5 mx-auto my-5" },
          [
            _c(
              "LinkButton",
              {
                attrs: {
                  classList: "px-3 py-4 bg-base-yellow",
                  processing: _vm.submitting,
                },
                on: { clickButton: _vm.saveMovie },
              },
              [_vm._v(" " + _vm._s(_vm.submitTitle(_vm.movie.status)) + " ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "grid grid-cols-12 gap-6 gap-y-6" }, [
          _c(
            "button",
            {
              staticClass:
                "col-span-3 py-4 font-bold relative bg-light-yellow disabled:bg-light-grey disabled:cursor-not-allowed",
              attrs: { disabled: !_vm.prevPage },
              on: {
                click: function ($event) {
                  return _vm.changeActive(_vm.prevPage)
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-4 arrow-icon-reverse svg-reverse absolute",
                attrs: {
                  src: require("@/assets/image/iconmonstr-arrow-25.svg"),
                },
              }),
              _vm._v(" 戻る "),
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "col-span-3 py-4 bg-light-yellow font-bold disabled:bg-light-grey disabled:cursor-not-allowed",
              attrs: { disabled: _vm.movie.status !== 1 },
              on: { click: _vm.temporarySave },
            },
            [_vm._v(" 一時保存 ")]
          ),
          _c(
            "button",
            {
              staticClass: "col-span-3 py-4 bg-light-yellow font-bold",
              on: {
                click: function ($event) {
                  return _vm.previewMovie()
                },
              },
            },
            [
              _vm._v(" プレビュー "),
              _c("img", {
                staticClass: "inline-block w-4 h-4 ml-2",
                attrs: { src: require("@/assets/image/icon_web_link.svg") },
              }),
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "col-span-3 py-4 font-bold relative bg-light-yellow disabled:bg-light-grey disabled:cursor-not-allowed",
              attrs: { disabled: !_vm.nextPage },
              on: {
                click: function ($event) {
                  return _vm.changeActive(_vm.nextPage)
                },
              },
            },
            [
              _vm._v(" 次へ "),
              _c("img", {
                staticClass: "h-4 arrow-icon absolute",
                attrs: {
                  src: require("@/assets/image/iconmonstr-arrow-25.svg"),
                },
              }),
            ]
          ),
        ]),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "successNoticeModal",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }